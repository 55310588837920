<template>
  <div class="content03">
    <p class="message">
      お問い合わせ内容を送信しました。<br />スタッフよりご連絡いたしますので<br />しばらくお待ちください。
    </p>
    <div class="wrap">
      <p class="link">
        <router-link to="/" class="txt-link">MOGトップページへ</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InquiryThanks",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
